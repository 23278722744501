<template>
  <div>
    <!--begin::Row-->
    <!-- <div class="row">
        <div class="col-lg-12"> -->
            <div class="card card-custom card-stretch gutter-b">
                <!--begin::Header-->
                <div class="card-header border-0">
                    <h3 class="card-title font-weight-bolder text-dark">List PPB Kabupaten / Kota</h3>
                    <div class="card-toolbar">
                        
                    </div>
                </div>
                <!--end::Header-->
                <!--begin::Body-->
                <div class="card-body pt-0">
                    <!--begin::Item-->
                    <div class="mb-6">
                        <!--begin::Content-->
                        <div>
                            <div>
                                <v-card-title>
                                List PPB Kabupaten / Kota
                                <v-spacer></v-spacer>
                                <v-text-field
                                    v-model="search"
                                    append-icon="search"
                                    label="Search"
                                    single-line
                                    hide-details
                                ></v-text-field>
                                </v-card-title>
                                <v-data-table
                                :headers="headers"
                                :items="profil"
                                :search="search"
                                >
                                <template v-slot:item="row">
                                        <tr>
                                        <td>{{row.item.idptsp}}</td>
                                        <td>{{row.item.daerah}}</td>
                                        <td>{{row.item.ketua}}</td>
                                        <td>{{row.item.alamat}}</td>
                                        <td>{{row.item.email}}</td>
                                        <td>{{row.item.notelp}}</td>
                                        <td>
                                            <b-button @click="url(row.item.idptsp)" size="sm" variant="success"><i class="flaticon-eye"></i></b-button>
                                        </td>
                                        <td>
                                            <b-button @click="kirimakun(row.item.idptsp)" size="sm" style="background-color: #030A8C; border-color: #030A8C" variant="primary"><i class="flaticon-paper-plane"></i>Kirim</b-button>
                                        </td>
                                        </tr>
                                    </template></v-data-table>
                            <!-- </v-card> -->
                            </div>
                        </div>
                        <!--end::Content-->
                    </div>
                    <!--end::Item-->
                </div>
                <!--end: Card Body-->
            </div>

        </div>
</template>

<script>
import KTCodePreview from "@/view/content/CodePreview.vue";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import axios from 'axios';

export default {
  data() {
    return {
      urlpenilaian: localStorage.getItem('baseapipenilaian'),
      headers: [
            {
            text: 'Kode PTSP',
            align: 'left',
            sortable: false,
            value: 'idptsp',
            },
            { text: 'Nama Provinsi', value: 'daerah' },
            { text: 'Nama Ketua', value: 'ketua' },
            { text: 'Alamat', value: 'alamat' },
            { text: 'Email', value: 'email' },
            { text: 'No Hp', value: 'notelp' },
            { text: 'Lihat Profil', value: '' },
            { text: 'Kirim Akun', value: '' },
        ],
        search:'',
        profil:[],
        role:'ppb_kab'
    };
  },
  components: {
    KTCodePreview
  },
  methods: {
    loaddata(){
            axios.get(this.urlpenilaian+"/profil_ppb_pemda?filter=idptsp,>,99")
            .then(response => {
                this.profil = response.data.data;
                // this.idpenilaian=response.data.data.id;
                console.log(response.data.data)
            }).catch(error=>{
                // localStorage.setItem('idpenilaian', 'undefined')
                console.log(error)
                return error
                // this.loaddata()
                });

            
    },
    url(kode_wilayah){
      let routeData = this.$router.resolve(
        {
          path: `/list-peserta/profilppbdetail/${kode_wilayah}`, 
          // query: {'dataParameter': kode_wilayah}
        });
        window.open(routeData.href, '_blank');
        //                window.open(`/ppk/detailpm/${kode_wilayah}`, '_blank')
    },
    lihat(id){
        localStorage.setItem('idprofil', id)
        this.$router.push({ name: "Profil-PTSP" })
    },
    kirimakun(idptsp){
      const id = idptsp;
      const role = this.role;
      axios
        .post(
          this.urlpenilaian+"/mailing",
          {
            id,
            role,
            headers: {
              "Content-type": "application/x-www-form-urlencoded",
            },
          }
        )
        .then((res) => {
            this.$bvToast.toast('Berhasil Dikirim', {
                title: `Success `,
                variant: `success`,
                solid: true
            })
            // this.loaddata()
            this.loaddata()
            // localStorage.setItem('idptsp', JSON.stringify(res.data.data.idptsp))
          return res;
        })
        .catch((err) => {
          console.log(err)
          this.$bvToast.toast('Gagal Disimpan', {
          title: `Failed `,
          variant: `danger`,
          solid: true
        })
          return err;
        });
    },
    setActiveTab1(event) {
      this.tabIndex = this.setActiveTab(event);
    },
    setActiveTab2(event) {
      this.tabIndex2 = this.setActiveTab(event);
    },
    /**
     * Set current active on click
     * @param event
     */
    setActiveTab(event) {
      // get all tab links
      const tab = event.target.closest('[role="tablist"]');
      const links = tab.querySelectorAll(".nav-link");
      // remove active tab links
      for (let i = 0; i < links.length; i++) {
        links[i].classList.remove("active");
      }

      // set current active tab
      event.target.classList.add("active");

      // set clicked tab index to bootstrap tab
      return parseInt(event.target.getAttribute("data-tab"));
    }
  },
  mounted() {
    this.loaddata()
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "List PPB Kabupaten / Kota" }
    ]);
  }
};
</script>
